<template>
  <div>
    <textarea :id="tinymceId" v-model="tinymceHtml"></textarea>
  </div>
</template>
<script>
  export default {
    props: ["id", "rows", "upload"],
    data() {
      const ide = Date.now()
      return {
        editor: null,
        tinymceId: ide,
        tinymceHtml: '',
        DefaultInit: {
          language_url: '/tinymce/langs/zh_CN.js',  //导入语言文件
          language: "zh_CN",//语言设置
          skin_url: '/tinymce/skins/ui/oxide',//主题样式
          height: 500, //高度
          menubar: false,// 隐藏最上方menu菜单
          toolbar: true,//false禁用工具栏（隐藏工具栏）
          browser_spellcheck: true, // 拼写检查
          branding: false, // 去水印
          statusbar: false, // 隐藏编辑器底部的状态栏
          elementpath: false,  //禁用下角的当前标签路径
          paste_data_images: true, // 允许粘贴图像
          toolbar: ['bold italic underline strikethrough blockquote|forecolor backcolor|formatselect |fontsizeselect  | alignleft aligncenter alignright alignjustify | outdent indent |codeformat blockformats| removeformat undo redo ',
            'bullist numlist toc pastetext|codesample charmap  hr insertdatetime |lists image media table link unlink |code searchreplace fullscreen help '],
          plugins: 'lists image media table wordcount code fullscreen help codesample toc insertdatetime  searchreplace  link charmap paste hr',
          images_upload_credentials: true,
        }
      };
    },
    mounted() {
      // this.editor = tinymce.init({
      //   selector: "#" + this.id,
      //   //skin:'oxide-dark',
      //   language: "zh_CN",
      //   plugins:
      //     "print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template code codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help emoticons autosave",
      //   toolbar:
      //     "code undo redo restoredraft | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | \
      //                styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat | \
      //                table image media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen",
      //   height: 650, //编辑器高度
      //   min_height: 400,
      //   /*content_css: [ //可设置编辑区内容展示的css，谨慎使用
      //                     '/static/reset.css',
      //                     '/static/ax.css',
      //                     '/static/css.css',
      //                 ],*/
      //   fontsize_formats: "12px 14px 16px 18px 24px 36px 48px 56px 72px",
      //   font_formats:
      //     "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings,zapf dingbats;知乎配置=BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;小米配置=Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif",
      //   link_list: [{ title: "预置链接1", value: "" }],
      //   image_list: [
      //     // {
      //     //   title: "预置图片1",
      //     //   value: "https://www.tiny.cloud/images/glyph-tinymce@2x.png",
      //     // },
      //     // { title: "预置图片2", value: "https://www.baidu.com/img/bd_logo1.png" },
      //   ],
      //   image_class_list: [
      //     { title: "None", value: "" },
      //     { title: "Some class", value: "class-name" },
      //   ],
      //   //importcss_append: true,
      //   //自定义文件选择器的回调内容
      //   file_picker_callback: function (callback, value, meta) {
      //     if (meta.filetype === "file") {
      //       callback("https://www.baidu.com/img/bd_logo1.png", {
      //         text: "My text",
      //       });
      //     }
      //     if (meta.filetype === "image") {
      //       console.log('aaa')
      //       callback("https://www.baidu.com/img/bd_logo1.png", {
      //         alt: "My alt text",
      //       });
      //     }
      //     if (meta.filetype === "media") {
      //       callback("movie.mp4", {
      //         source2: "alt.ogg",
      //         poster: "https://www.baidu.com/img/bd_logo1.png",
      //       });
      //     }
      //   },
      //   //为内容模板插件提供预置模板
      //   templates: [
      //     { title: "模板1", description: "介绍文字1", content: "模板内容" },
      //     {
      //       title: "模板2",
      //       description: "介绍文字2",
      //       content:
      //         '<div class="mceTmpl"><span class="cdate">CDATE</span>，<span class="mdate">MDATE</span>，我的内容</div>',
      //     },
      //   ],
      //   //content_security_policy: "script-src *;",
      //   extended_valid_elements: "script[src]",
      //   //
      //   template_cdate_format: "[CDATE: %m/%d/%Y : %H:%M:%S]",
      //   template_mdate_format: "[MDATE: %m/%d/%Y : %H:%M:%S]",
      //   autosave_ask_before_unload: false,
      //   toolbar_mode: "wrap",
      //   paste_data_images: true, // 设置为“true”将允许粘贴图像，而将其设置为“false”将不允许粘贴图像
      //   images_upload_url: 'http://192.168.11.253:8080/qiniu/examples/upload_simple_file.php',// 'http://192.168.11.253:8080/qiniu/examples/upload_simple_file.php', //"http://localhost:5000/api/upload",
      //   images_upload_base_path: "",
      //   automatic_uploads: true,
      // image_upload_handler: function (blobInfo, success, failure, progress) {
      //   var xhr, formData;
      //   formData = new FormData();
      //   xhr = new XMLHttpRequest();
      //   xhr.withCredentials = false;
      //   xhr.open('POST', 'http://192.168.11.253:8080/qiniu/examples/upload_simple_file.php');
      //   xhr.onload = function () {
      //     var json;
      //     if (xhr.status === 403) {
      //       failure('HTTP Error: ' + xhr.status, { remove: true });
      //       return;
      //     }
      //     if (xhr.status < 200 || xhr.status >= 300) {
      //       failure('HTTP Error: ' + xhr.status);
      //       return;
      //     }
      //     json = JSON.parse(xhr.responseText);
      //     console.log(json)
      //     // if (!json || typeof json.location != 'string') {
      //     //   failure('Invalid JSON: ' + xhr.responseText);
      //     //   return;
      //     // }

      //     // success(json.location);
      //   };
      //   // xhr.onerror = function () {
      //   //   failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
      //   // };
      //   formData = new FormData();
      //   console.log(blobInfo);
      //   formData.append('file', blobInfo.blob(), blobInfo.filename());
      //   xhr.send(formData);
      // }
      // });
      this.init()
    },
    methods: {
      init() {
        const self = this;
        window.tinymce.init({

          // 默认配置
          ...this.DefaultInit,
          // 图片上传
          images_upload_handler: function (blobInfo, success, failure) {
            let formData = new FormData()
            formData.append('file', blobInfo.blob())
            self.axios.post('http://192.168.11.253:8080/qiniu/examples/upload_simple_file.php', formData)
            // self.axios.post('http://112.250.105.14:10087/qiniu/examples/upload_simple_file.php', formData)
              .then(response => {
                if (response.data.code == 0) {
                  success(response.data.file);
                } else {
                  failure('上传失败！')
                }
              })
          },
          // 挂载的DOM对象
          selector: `#${this.tinymceId}`,
        })

      }
    },
  };
</script>